import React, { forwardRef } from 'react';
import styled from 'styled-components';

import { size } from 'modules/defines/styles';

const Wrapper = styled.div`
  /*width: ${size.width};*/
  margin: 0 auto;
  ${props => props.theme === 'padding' && `
    padding-left: 80px;
    padding-right: 80px;
  `}
`;

const LayoutComponent = ({ theme, children }) => (
  <Wrapper theme={theme}>
    {children}
  </Wrapper>
);

export default LayoutComponent;

const FormLayoutWrapper = styled.div`
 
`;

export const FormLayout = forwardRef(({ children }, ref) => (
  <FormLayoutWrapper ref={ref}>
    {children}
  </FormLayoutWrapper>
));