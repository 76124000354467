import { createGlobalStyle } from 'styled-components';

export const size = {
  width: '1200px'
};

export const palette = {
  title: '#121212',
  subtitle: '#757575',
  text: '#565656',
};

export const fontDefault = '\'Alata\', sans-serif'; // '\'Roboto\', sans-serif;';
export const fontAbel = '\'Abel\', sans-serif';
export const fontKR = '\'Roboto\', sans-serif';

export const GlobalStyles = createGlobalStyle`
  body {
    margin: 0;
    font-family: ${fontDefault};
    font-size: 14px;
    color: #333;
    background-color: #fff;
    -webkit-font-smoothing: antialiased;
    font-size: 12px;
  }

  button {
    padding: 0;
    border: 0;
    border-radius: 0;
    background: transparent;
    cursor: pointer;
    font-family: ${fontDefault};
    color: inherit;
    pointer-events: fill;

    svg {
      pointer-events: none;
    }

    &:disabled {
      cursor: default;
    }
  }

  input[type='email'],
  input[type='text'],
  input[type='tel'],
  input[type='password'] {
    outline: 0;
    font-family: inherit;
    padding: 0;
    border-radius: 0;
    border: 0;
    ::placeholder {
      color: ${palette.lightGray};
    }
  }
  
  textarea {
    resize: none;
    border: 0;
    border-radius: 0;
    padding: 0;
    background-color: transparent;
    font-family: inherit;
    color: inherit;
  }

  h1, h2, h3, h4, h5, h6 {
    margin: 0;
    padding: 0;
  }

  * {
    box-sizing: border-box;
  }

  *:focus {
    outline: 0;
  }

  img,
  svg {
    display: block;
  }

  a {
    pointer-events: fill;
    color: inherit;
    text-decoration: none;

    svg {
      pointer-events: none;
    }
  }

  p {
    padding: 0;
    margin: 0;
  }

  ul, ol {
    list-style: none;
    padding: 0;
    margin: 0;
  }

  dl, dt, dd {
    padding: 0;
    margin: 0;
  }

  // remove autofill background-color
  input:-webkit-autofill,
  input:-webkit-autofill:hover, 
  input:-webkit-autofill:focus,
  textarea:-webkit-autofill,
  textarea:-webkit-autofill:hover,
  textarea:-webkit-autofill:focus,
  select:-webkit-autofill,
  select:-webkit-autofill:hover,
  select:-webkit-autofill:focus {
      -webkit-box-shadow: 0 0 0 1000px transparent inset;
      transition: background-color 5000s ease-in-out 0s;
  }
}`;