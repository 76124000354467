import React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

import LogoIcon from 'components/images/foot_logo.png';
import FooterImg01 from 'components/images/foot_img01.png';
import FooterImg02 from 'components/images/foot_img02.png';
import FooterBg01 from 'components/images/foot_bg01.png';
import FooterBg02 from 'components/images/foot_bg02.png';
import FooterBg03 from 'components/images/foot_bg03.png';
import { palette } from 'modules/defines/styles';
import { pagePaths } from 'modules/defines/paths';
import { fontAbel } from '../../modules/defines/styles';
import 'components/css/common.css';

const FooterComponent = () => (
  <>
    <footer>
      <div className='footer_box01'>
        <div className='bg_img'>
          <img src={FooterBg01} alt="" />
          <img src={FooterBg02} alt="" />
          <img src={FooterBg03} alt="" />
        </div>
        <div className='cont01'>
          <div className='img_box'>
            <img src={FooterImg01} alt="" />
          </div>
          <div className='text_box'>
            <h4>프로커넥트 APP 다운로드</h4>
            <p>프로커넥트 모바일 서비스에서는 언제 어디서나<br/> 프로젝트 문의 및 전문가 등록이 가능합니다.</p>
            <a href="https://play.google.com/store/apps/details?id=kr.co.squarebase.proconnect" target="_blank"><img src={FooterImg02} alt="" /></a>
          </div>
        </div>
      </div>
      <div className='footer_box02'>
        <div className='cont01'>
          <img src={LogoIcon} alt="ProConnect" />
        </div>
        <div className='cont02'>
          <ul>
            <li><a href={pagePaths.privacypolicy}>개인정보처리방침</a></li>
            <li><a href={pagePaths.termsOfService}>이용약관</a></li>
          </ul>
          <p>사업자등록번호 338-88-02008 <span></span> 주소 강원도 원주시 화실유암길 165-23 <br/> Tel 033-742-3832 <span></span> Email bolam.yi@proconnect.co.kr <span></span> 문의시간 평일 09:00~17:00</p>
        </div>
      </div>
      <div className='footer_box03'><p>Copyright &copy; 2022 Thirdage</p></div>
    </footer>
  </>
);

export default FooterComponent;