export const pagePaths = {
    main: "/",
    company: "/company",
    client: "/client",
    clientForm: "/client-write",
    expert: "/expert",
    expertForm: "/expert-write",
    seminar: "/seminar",
    event: "/event",
    faq: "/faq",
    expertSearch: "/expert-search",
    expertProfile: "/expert-profile",
    privacypolicy: "/PrivacyPolicy",
    termsOfService: "/TermsOfService",
};
