import AsyncComponent from "utils/asyncComponent";

export const Main = AsyncComponent(() => import("./Main"));
export const Company = AsyncComponent(() => import("./Company"));
export const Client = AsyncComponent(() => import("./Client"));
export const ClientForm = AsyncComponent(() => import("./ClientForm"));
export const Expert = AsyncComponent(() => import("./Expert"));
export const ExpertForm = AsyncComponent(() => import("./ExpertForm"));
export const Seminar = AsyncComponent(() => import("./Seminar"));
export const Event = AsyncComponent(() => import("./Event"));
export const Faq = AsyncComponent(() => import("./Faq"));
export const Privacy = AsyncComponent(() => import("./Privacy"));
export const ExpertSearch = AsyncComponent(() => import("./ExpertSearch"));
export const ExpertProfile = AsyncComponent(() => import("./ExpertProfile"));
export const PrivacyPolicy = AsyncComponent(() => import('./PrivacyPolicy'));
export const TermsOfService = AsyncComponent(() => import('./TermsOfService'));
// export const Mypage = AsyncComponentO(() =>import('./mypage'));
