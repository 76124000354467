import React from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";

import LogoIcon from "components/images/logo.png";
import { palette, size } from "modules/defines/styles";
import { pagePaths } from "modules/defines/paths";

import axios from "axios";
import "components/css/common.css";
import { useEffect, useState } from "react";
import $ from "jquery";

const Header = styled.header`
    /* min-width: ${size.width};*/
`;
const NavLink = styled.li`
    & + & {
        margin-left: 50px;
    }
    button,
    a {
        position: relative;
        display: block;
        height: 100%;
        line-height: 40px;
        color: ${palette.title};
        font-size: 16px;
        font-weight: 700;
    }
`;

const HeaderComponent = () => {
    useEffect(() => {
        $("header ul li>a").click(function () {
            $("header ul li>a").removeClass("header_on");
            $(this).addClass("header_on");
        });

        axios
            .get(
                //"http://proconnect.resimbase.gethompy.com/md_client/API_login_check.php"
                "http://proconnect.co.kr/md_client/API_login_check.php"
            )
            .then((res) => {
                //console.log("data:" + res.data);
                //console.log("data:" + res.data.indexOf("needlogin"));
                if (res.data.indexOf("needlogin") >= 0) {
                    SetLogin_state(
                        <div className="login_box">
                            <a href="/user_sub.php?gid=www&mu_idx=4">로그인</a>
                            <a href="/user_sub.php?gid=www&mu_idx=5">
                                회원가입
                            </a>
                        </div>
                    );
                } else if (res.data.indexOf("logon") >= 0) {
                    SetLogin_state(
                        <div className="login_box">
                            <a href="#none" id="login_mypage_btn">
                                마이페이지
                            </a>
                            <ul id="login_mypage">
                                <li>
                                    <a href="/user_sub.php?gid=www&mu_idx=20">
                                        회원정보 관리
                                    </a>
                                </li>
                                <li>
                                    <a href="/user_sub.php?gid=www&mu_idx=21">
                                        세미나&행사 예약현황
                                    </a>
                                </li>
                                <li>
                                    <a href="/fnc_mb/user_logout.php">
                                        로그아웃
                                    </a>
                                </li>
                            </ul>
                        </div>
                    );

                    $("#login_mypage_btn").click(function () {
                        if ($("#login_mypage").hasClass("mypage_on")) {
                            $("#login_mypage").removeClass("mypage_on");
                            $("#login_mypage").slideUp();
                        } else {
                            $("#login_mypage").addClass("mypage_on");
                            $("#login_mypage").slideDown();
                        }
                    });
                }
            });
    }, []);

    const [Login_state, SetLogin_state] = useState();
    // const logout = (
    //     <div className="login_box">
    //         <a href="/user_sub.php?gid=www&mu_idx=4">로그인</a>
    //         <a href="/user_sub.php?gid=www&mu_idx=5">회원가입</a>
    //     </div>
    // );

    // const logon = (
    //     <div className="login_box">
    //         <a href="#none" id="login_mypage_btn">
    //             마이페이지
    //         </a>
    //         <ul id="login_mypage">
    //             <li>
    //                 <a href="#">프로필 관리</a>
    //             </li>
    //             <li>
    //                 <a href="/user_sub.php?gid=www&mu_idx=19">프로젝트 관리</a>
    //             </li>
    //             <li>
    //                 <a href="/fnc_mb/user_logout.php">로그아웃</a>
    //             </li>
    //         </ul>
    //     </div>
    // );

    return (
        <><Header className="header">
            <div className="header_cover">
                <a href={pagePaths.main}>
                    <img src={LogoIcon} alt="ProConnect" />
                </a>
                <ul>
                    <li className="sub01">
                        <Link to={pagePaths.company}>회사소개</Link>
                    </li>
                    <li className="sub02">
                        <Link to={pagePaths.client}>Client</Link>
                    </li>
                    <li className="sub03">
                        <Link to={pagePaths.expert}>Expert</Link>
                    </li>
                    <li className="sub04">
                        <a href="/user_sub.php?gid=www&mu_idx=10">
                            세미나&행사
                        </a>
                    </li>
                    <li className="sub05_1">
                        <Link to={pagePaths.event}>Event</Link>
                    </li>
                    <li className="sub05">
                        <Link to={pagePaths.faq}>FAQ</Link>
                    </li>
                </ul>
                {Login_state}
            </div>
        </Header><div className="content_point"></div></>

    );
};
export default HeaderComponent;
