import React from 'react';

import LayoutComponent from 'components/layout/LayoutComponent';
import HeaderComponent from './HeaderComponent';
import FooterComponent from './FooterComponent';

const AppComponent = ({ children }) => (
  <LayoutComponent>
    <HeaderComponent />
    {children}
    <FooterComponent />
  </LayoutComponent>
);

export default AppComponent;