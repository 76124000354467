import React from "react";
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";

import {
    Main,
    Company,
    Client,
    ClientForm,
    Expert,
    ExpertForm,
    Seminar,
    Event,
    Faq,
    Privacy,
    Mypage,
    ExpertSearch,
    ExpertProfile,
    PrivacyPolicy,
    TermsOfService,
} from "pages";
import { pagePaths } from "modules/defines/paths";
import { GlobalStyles } from "modules/defines/styles";
import AppComponent from "components/common/AppComponent";

const App = () => {
    return (
        <BrowserRouter>
            <GlobalStyles />
            <AppComponent>
                <Routes>
                    <Route path={pagePaths.main} element={<Main />} />
                    <Route path={pagePaths.company} element={<Company />} />
                    <Route path={pagePaths.client} element={<Client />} />
                    <Route
                        path={pagePaths.clientForm}
                        element={<ClientForm />}
                    />
                    <Route path={pagePaths.expert} element={<Expert />} />
                    <Route
                        path={pagePaths.expertForm}
                        element={<ExpertForm />}
                    />
                    <Route path={pagePaths.seminar} element={<Seminar />} />
                    <Route path={pagePaths.event} element={<Event />} />
                    <Route path={pagePaths.faq} element={<Faq />} />
                    <Route path={pagePaths.privacy} element={<Privacy />} />
                    <Route
                        path={pagePaths.expertSearch}
                        element={<ExpertSearch />}
                    />
                    {/* <Route path={pagePaths.mypage} element={<Mypage />} /> */}
                    <Route
                        path="*"
                        element={<Navigate to={pagePaths.main} />}
                    />

                    <Route
                        path={pagePaths.expertProfile}
                        element={<ExpertProfile />}
                    />
                    <Route path={pagePaths.privacypolicy} element={<PrivacyPolicy />} />
                    <Route path={pagePaths.termsOfService} element={<TermsOfService />} />
                </Routes>
            </AppComponent>
        </BrowserRouter>
    );
};

export default App;
